import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { ConditionState } from 'components/common/SmartFilter/enums';

import { IGroup } from 'domain/group/types';
import { IHolidayCalendar } from 'domain/holiday/calendar/types';
import { ITechnology } from 'domain/technology/types';
import { UserType } from 'domain/user/enums';
import { IUser } from 'domain/user/types';

import { formatToDateWithFixedTimeZone, convertDateToUTCString } from 'utils/dateTime';

export interface IUpdateUserFormData {
  firstName: string;
  lastName: string;
  email: string;
  slackEmail: string;
  dateOfBirth?: Date | null;
  userType: UserType;
  location?: string;
  group: IGroup;
  phoneNumber?: string;
  calendar: IHolidayCalendar;
  hoursPerWeek: number;
  state: ConditionState;
  employmentStartDate: Date;
  billableStartDate: Date;
  employmentEndDate?: Date | null;
  technologies?: Array<ITechnology>;
}

export interface IPreparedUpdateUserFormData
  extends Omit<
    IUpdateUserFormData,
    | 'dateOfBirth'
    | 'group'
    | 'calendar'
    | 'employmentStartDate'
    | 'billableStartDate'
    | 'employmentEndDate'
    | 'technologies'
  > {
  dateOfBirth: string;
  groupId: number;
  calendarId: number;
  employmentStartDate: string;
  billableStartDate: string;
  employmentEndDate: string | null;
  technologyIds?: Array<number>;
}

export interface IUpdateUserFormDataToSubmit {
  id: number;
  formData: IPreparedUpdateUserFormData;
}

const requiredValidation = 'forms:validation:required';

const dateFormatValidation = 'forms:validation:dateFormat';

export const buildValidationFields = () => ({
  firstName: yup.string().required(i18n.t(requiredValidation)).default(''),
  lastName: yup.string().required(i18n.t(requiredValidation)).default(''),
  email: yup.string().email(i18n.t('forms:validation.email')).required(i18n.t(requiredValidation)).default(''),
  slackEmail: yup
    .string()
    .email(i18n.t('forms:validation.slackEmail'))
    .required(i18n.t(requiredValidation))
    .default(''),
  userType: yup.mixed<UserType>().required(i18n.t(requiredValidation)).default(UserType.user),
  dateOfBirth: yup.date().nullable().default(null),
  location: yup
    .string()
    .max(100, i18n.t('forms:validation:maxSymbols', { count: 100 }))
    .nullable()
    .default(''),
  group: yup.mixed<IGroup>().required(i18n.t(requiredValidation)).nullable().default(null),
  calendar: yup.mixed<IHolidayCalendar>().required(i18n.t(requiredValidation)).nullable().default(null),
  phoneNumber: yup.string().nullable().default(''),
  state: yup.mixed<ConditionState>().required(i18n.t(requiredValidation)).default(ConditionState.active),
  employmentStartDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .required(i18n.t(requiredValidation))
    .default(new Date()),
  billableStartDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .required(i18n.t(requiredValidation))
    .min(yup.ref('employmentStartDate'), i18n.t('forms:validation:startDateBeforeStartBillableDate'))
    .default(new Date()),
  employmentEndDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .nullable()
    .min(yup.ref('billableStartDate'), i18n.t('forms:validation:endDateBeforeStartBillableDate'))
    .default(null),
  technologies: yup.array().of(yup.mixed<ITechnology>()).default([]),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (user: IUser) => {
  return {
    ...user,
    dateOfBirth: _.isNil(user.dateOfBirth) ? null : formatToDateWithFixedTimeZone(user.dateOfBirth),
    employmentStartDate: formatToDateWithFixedTimeZone(user.employmentStartDate),
    billableStartDate: formatToDateWithFixedTimeZone(user.billableStartDate),
    employmentEndDate: _.isNil(user.employmentEndDate) ? null : formatToDateWithFixedTimeZone(user.employmentEndDate),
  };
};

export const formatAttributesToSubmit = (
  userId: number,
  formData: IUpdateUserFormData,
): IUpdateUserFormDataToSubmit => {
  const {
    firstName,
    lastName,
    email,
    slackEmail,
    userType,
    dateOfBirth,
    location,
    group,
    calendar,
    hoursPerWeek,
    phoneNumber,
    state,
    employmentStartDate,
    billableStartDate,
    employmentEndDate,
    technologies,
  } = formData;

  const technologyIds = technologies?.map(({ id }) => id) ?? [];

  return {
    id: userId,
    formData: {
      firstName,
      lastName,
      email,
      slackEmail,
      userType,
      dateOfBirth: _.isNil(dateOfBirth) ? '' : convertDateToUTCString(dateOfBirth),
      location,
      groupId: group.id,
      calendarId: calendar.id,
      hoursPerWeek,
      phoneNumber,
      state,
      employmentStartDate: convertDateToUTCString(employmentStartDate),
      billableStartDate: convertDateToUTCString(billableStartDate),
      employmentEndDate: _.isNil(employmentEndDate) ? null : convertDateToUTCString(employmentEndDate),
      technologyIds,
    },
  };
};

export default {
  validation: {
    email: 'Incorrect email address',
    slackEmail: 'Incorrect email address',
    required: 'Field is required',
    password: 'Password must match',
    minimumSymbols: 'Password must consist of 8 symbols',
    maxDigitsAfterDecimalTest: 'maxDigitsAfterDecimal',
    maxTwoDigitsAfterDecimal: 'Number must have 2 digits after decimal or less',
    maxOneDigitsAfterDecimal: 'Number must have 1 digit after decimal',
    maxSymbols_one: 'String must contain maximum of {{ count }} character',
    maxSymbols_other: 'String must contain maximum of {{ count }} characters',
    mustBeNumber: 'Must be a number',
    minNumber: 'Number must be at least {{ count }}',
    maxNumber: 'Number must not be more than {{ count }}',
    positiveNumber: 'Number must be positive',
    startDateBeforeEndDate: "End date can't be before start date",
    startDateBeforeStartBillableDate: "Start billable date can't be before start date",
    endDateBeforeStartBillableDate: "End date can't be before start billable date",
    betweenNumber: 'Number must be no less than {{ count1 }} and no more than {{ count2 }}',
    phoneNumber: 'Phone number is not valid',
    minItemsInArray_one: 'Field must have at least {{ count }} element',
    minItemsInArray_other: 'Field must have at least {{ count }} elements',
    description: "Description can't be blank",
    trackable: 'Project is required',
    dateFormat: 'Wrong date format. Please input a date in format mm/dd/yyyy',
  },
};
